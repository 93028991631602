import React from "react"
import News from "../../../blocks/en/news_page"
import { Link, graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "ninua_paok" }) {
    ...NewsInfoEn
  }
}`

export default class NewsTextEn extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Catenaccio Sports Agency, together with Levan Gabrichidze, the official agent of Niko Ninua, the 21 year old captain of Dynamo Tbilisi, after long negotiations had successfully reached an agreement with all the parties involved and organized the transfer of Niko Ninua from Dynamo Tbilisi to PAOK Thessaloniki.</p>
          <p>The player signed a four-year contract.</p>
          <p>Catenaccio Sports Agency wishes Niko Ninua a swift debut in FC PAOK as well as the Georgian national team!</p>
          <div className="video">
            <iframe
              src="https://www.youtube.com/embed/txe8XBlzaeE"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </div>
        </div>
      </div>
    )
  }
}